import React, { useEffect } from "react";
import Team from "./Team";
import useIntersect from "../../util/useIntersect";
import AboutText from "./AboutText";
import { setPosition } from "../../../actions/mainActions";
import { connect } from "react-redux";

const About = ({ setPosition }) => {
  const [ref, entry] = useIntersect({
    threshold: 0,
    rootMargin: "-50% 0% -50% 0%",
  });
  useEffect(() => {
    if (entry.isIntersecting) {
      document.querySelectorAll(".nav-active").forEach((i) => {
        i.classList.remove("nav-active");
      });
      document.getElementById("nav-about").classList.add("nav-active");
      setPosition("about");
    }
  }, [entry.isIntersecting]);
  return (
    <div id="about" ref={ref}>
      {/* <Team /> */}
      <AboutText />
    </div>
  );
};

export default connect(null, {
  setPosition,
})(About);
